@import '@aurora/shared-client/styles/_variables.pcss';

.lia-content-wrap {
  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }
}

.lia-cookie-banner {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 310px;
  width: calc(100% - 1.75rem);
  border-radius: var(--lia-bs-border-radius-lg);
  background-color: var(--lia-bs-white);
  box-shadow: var(--lia-bs-box-shadow-lg);
  font-weight: var(--lia-bs-font-weight-normal);
  font-size: var(--lia-bs-font-size-sm);
  padding: 15px;
  align-self: center;
  text-align: center;

  @media (--lia-breakpoint-down-lg) {
    font-size: var(--lia-font-size-xs);
    padding: 10px;
  }
}

.lia-cookie-banner-small {
  font-size: var(--lia-font-size-xs);
  padding: 10px;
}

.lia-cookie-banner-container.lia-cookie-banner-container {
  display: flex;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  justify-content: center;
}

.lia-cookie-consent-button {
  margin-top: 15px;
  align-self: center;
  color: var(--lia-btn-secondary-text-color);
  background-color: var(--lia-btn-secondary-bg-color);
  border: var(--lia-btn-secondary-border);
  box-shadow: none;
  cursor: pointer;
  font-style: var(--lia-btn-font-style);
  font-weight: var(--lia-bs-btn-font-weight);
  padding: 5px 12px;
  border-radius: var(--lia-bs-btn-border-radius);
}
