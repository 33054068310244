@import '@aurora/shared-client/styles/mixins/_panel.pcss';
@import '@aurora/shared-client/styles/_variables.pcss';

$gutterWidth: calc(var(--lia-bs-grid-gutter-width) * 0.5);

.lia-wrapper {
  background-color: var(--lia-local-bg-color);

  /* Add padding to the top and bottom of the wrapper if there is a panel */
  &:has(.lia-article.lia-with-background) {
    padding-top: 15px;
    padding-bottom: 15px;

    /* If it's not in a ThemedContainer, add padding to the left and right of the wrapper */
    &:not(:has(.lia-themed-container)) {
      padding-left: $gutterWidth;
      padding-right: $gutterWidth;
    }
    @media (--lia-breakpoint-down-xs) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.lia-article {
  display: flex;
  flex-direction: column;

  &.lia-with-background {
    @include panel-defaults();
    padding: 25px;
  }
  &.lia-sdk-local-override {
    outline: var(--lia-sdk-widget-outline);
  }
}

.lia-quilt-wrapper-controls {
  position: relative;
  z-index: 1;
  min-height: 56px;
}
